import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from './Components/Menu';
import About from './Components/About';
import Photo from './Components/Photo';
import Contact from './Components/Contact';
import Graphic from './Components/Graphic';
// import Light from './Components/Light';

import Footer from './Components/Footer';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
		<BrowserRouter>
		<Menu />
		{/* <About /> */}
			<Routes>
					<Route exact path="/" Component={About} />
					<Route path="/photo" Component={Photo} />
					<Route path="/graphic" Component={Graphic} />
					{/* <Route path="/light" Component={Light} /> */}
					<Route path="/contact" Component={Contact} />
			</Routes>
			<Footer/>
		</BrowserRouter>
		
    </div>
  );
}

export default App;
