import React, { useState } from 'react'
import Image from 'react-bootstrap/Image';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
 

const images = [
    {path: "img1.jpg", id: 1, description: "asdf"},
    {path: "img2.jpg", id: 2, description: "asdfd"},
    {path: "img3.jpg", id: 3, description: "fdgf23"},
    {path: "img4.jpg", id: 4, description: "dsfdhdf"},
    {path: "img5.jpg", id: 5, description: "sdfgnrtddrvs"},
    {path: "img6.jpg", id: 6, description: "sdfsfklsfmlksmflksmdflk"},
    {path: "img7.jpg", id: 7, description: "sdfmkdsmfølsddfldsmremlgjsv"},
    {path: "img8.jpg", id: 8, description: "øvklsmfsndflkdsmlkfn"},
    {path: "img9.jpg", id: 9, description: "øsdklm"},
    {path: "img10.jpg", id: 10, description: "sdfgbfd"},
    {path: "img11.jpg", id: 11, description: "12345"},
    {path: "img12.jpg", id: 12, description: "dklsmdkm"},
]


const Photo = () => {
    const [data, setData] = useState({img: '', i: 0})

    const viewImage = (img, i) => {
        setData({img, i})
    }

    const closeImage = () => {
        setData({img: '', i: 0})
    }

    return (
        <>
        {
            data.img &&
                <div onClick={closeImage} style={{width: "100%", height: "140vh", marginTop: "-30vh", cursor: "pointer"}} className='bg-dark position-fixed d-flex flex-column justify-content-center align-items-center overflow-hidden'>
                    <Image src={`images/${data.img.path}`} style={{maxWidth: "80%", maxHeight: "70%",  cursor: "pointer"}} />
                    <p className='text-light p-2'>{data.img.description}</p>
                </div>
        }

                <ResponsiveMasonry className="p-1" columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                    <Masonry>
                        {images.map(img => (
                            <Image key={img.id} src={`images/${img.path}`} className="d-block w-100 p-1" onClick={() => viewImage(img, img.id)} style={{ cursor: "pointer"}}/>
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
        </>
    )
}

export default Photo
