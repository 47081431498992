import React from 'react'
import { Container } from 'react-bootstrap'
import Image from 'react-bootstrap/Image';
import img from '../assets/imgs/portrait.jpg'



const About = () => {
  return (
    <div className='p-5'>
        <Container className='d-flex align-items-center flex-column'>
            <h1>OM MEG</h1>
            <Image src={img} className="col-md-7 col-sm-10 mt-5 mb-5 mh-25 mw-100"/>
            <p className="p-2 col-10 text-center">
              Hei! Jeg heter Kamilla Kvamme. Jeg er en fotograf, grafisk designer og lysdesigner bosatt i Oslo. 
            </p>        
        </Container>
        
    </div>
  )
}

export default About
