import React, { useRef, useState } from 'react'
import { Button, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';




const Result = (props) => {
    
    return (
        <p style={{color: "#F00"}}>{props.innerText}</p>
    )
}

const Contact = () => {

    const [result, showResult] = useState(false)

    const form = useRef();

    let resultText = '';

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(process.env.REACT_APP_MAIL_SERVICE, process.env.REACT_APP_MAIL_TEMPLATE, form.current, {publicKey: process.env.REACT_APP_PUBLIC_KEY})
        .then((result) => {
            console.log(result.text);
            resultText = "E-post sendt!";
        }, (error) => {
            resultText = "Noe gikk galt, prøv igjen senere";
            console.log(error.text);
        });
        e.target.reset();
        showResult(true)
    };

    setTimeout(() => {showResult(false)}, 4000)

    return (
        <Container className='mt-5 p-1 p-md-5'>
            <h1 className='text-center'>Kontakt</h1>
            <p className='text-center'>Send meg en mail om du ønsker å diskutere et samarbeid</p>
            <Form className='p-5 bg-white mt-5' ref={form} onSubmit={sendEmail}>
                <Form.Group className="mb-3">
                    <Form.Label>Navn</Form.Label>
                    <Form.Control type="text" name="from_name" placeholder='Fullt navn' />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>E-post</Form.Label>
                    <Form.Control type="email" name="from_email" placeholder='name@example.com'/>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" name="message" rows={8} />
                </Form.Group>

                <Form.Group className="mb-3 text-center">
                    <Button type="submit" value="Send" className='w-25 p-2' variant="dark">Send</Button>
                    <div className='result-div'>{result ? <Result innerText={resultText}/> : null}</div>
                </Form.Group>

            </Form>
        </Container>
  );
}

export default Contact
