import React from 'react'
import {LinkContainer} from 'react-router-bootstrap'
import Nav from 'react-bootstrap/Nav';




const Footer = () => {
  return (
    <div className='d-flex flex-column justify-content-center p-3 mt-5 gap-3'>
        {/* <div>SoMe</div> */}
        <div className='d-flex flex-row justify-content-center gap-3'>
            <LinkContainer to='/'>
              <Nav.Link>Hjem</Nav.Link>
            </LinkContainer>
            <LinkContainer to='/photo'>
              <Nav.Link>Foto</Nav.Link>
            </LinkContainer>
            <LinkContainer to='/graphic'>
              <Nav.Link>Grafisk</Nav.Link>
            </LinkContainer>

            {/* <LinkContainer to='/light'>
              <Nav.Link>Lysdesign</Nav.Link>
            </LinkContainer> */}
            <LinkContainer to='/contact'>
              <Nav.Link>Kontakt</Nav.Link>
            </LinkContainer>
            </div>
            <div className='d-flex flex-row justify-content-center gap-3'>
            <a className="text-decoration-none link-dark" href='https://audunslungard.no/' target='_blank'rel="noreferrer">Made by Audun Slungård</a>
            </div>
          
    </div>
  )
}

export default Footer
