import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import {LinkContainer} from 'react-router-bootstrap'
import img from '../assets/imgs/portrait.jpg'

const Menu = () => {
  return (
    <Navbar bg="dark" data-bs-theme="dark" className='p-lg-5 p-sm-3'>
      <Container>
        <LinkContainer to='/'>
        <Navbar.Brand className='d-flex flex-row-reverse' >
            <Image src={img} roundedCircle style={{width: '80px', height: '80px'}} className=''/>
        </Navbar.Brand>
        </LinkContainer>
          <Nav className="me-auto">
            <LinkContainer to='/photo'>
            <Nav.Link>Foto</Nav.Link>
            </LinkContainer>
            <LinkContainer to='/graphic'>
            <Nav.Link>Grafisk</Nav.Link>
            </LinkContainer>
            {/* <LinkContainer to='/light'>
            <Nav.Link>Lysdesign</Nav.Link>
            </LinkContainer> */}
            <LinkContainer to='/contact'>
            <Nav.Link>Kontakt</Nav.Link>
            </LinkContainer>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default Menu
