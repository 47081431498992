import React from 'react'
import { Image } from 'react-bootstrap'

const Graphic = () => {

  const projects = [
    { id: 1, imgPath: "magazine-cover1.png", headerText: "Stopp Dyremishandling - Høst 2022", text: "Medlemsblad for Anima Norge, 2022. Laget i Adobe Indesign. I tillegg til design har jeg også produsert tekst og fungert som redaktør i bladet.", urlText: "Se hele bladet på Issuu", url: "https://issuu.com/anima-norge/docs/stopp_dyremishandling-22_issuu"},
    { id: 2, imgPath: "magazine-cover2.png", headerText: "Stopp Dyremishandling - Vinter 2023", text: "Medlemsblad for Anima Norge, 2023. Laget i Adobe Indesign. I tillegg til design har jeg også produsert tekst og fungert som redaktør i bladet.", urlText: "Se hele bladet på Issuu", url: "https://issuu.com/anima-norge/docs/stopp_dyremishandling-23.1" },
    { id: 3, imgPath: "sungazer.png", headerText: "Sungazer - konsertplakat 2022", text: "Konsertplakat for Sub Scene, 2022. Laget i Adobe Photoshop og Illustrator.", urlText: "", url: ""},

  ]

  const reverse = (id) => {
    if (id % 2 === 0) {
      return 'flex-lg-row'
    } else {
      return 'flex-lg-row-reverse'
    }
  }

  return (


    projects.map((project) => {
      return (
        <div key={project.id} className={`d-flex ${reverse(project.id)} flex-column bg-white m-4`}>
          <div className='d-flex align-items-center col-12 col-lg-6 p-4' >
            <Image src={`images/${project.imgPath}`} className='w-100' />
          </div>
          <div className='d-flex flex-column col-12 col-lg-6 justify-content-center p-4'>
            <h1 className=''>{project.headerText}</h1>
            <p className=''>{project.text}</p>
            <a className='text-decoration-none' href={project.url} rel="noreferrer" target='_blank'>{project.urlText}</a>
          </div>
        </div>
      )
    })

  )
}

export default Graphic
